@use "sass:map";
@use "@angular/material" as mat;

@use '/src/scss/breakpoints';
@use '/src/scss/colors';

/* The use of !important throughout this file is to override inline
styles as part of the Angular Material default radio theme */

@mixin color($theme) {
	$color-config: mat.get-color-config($theme);

	.mdc-radio {		
		.mdc-radio__native-control {
			&:enabled {
				~.mdc-radio__background {
					// Remove the default overlay on hover
					&::before {
						background-color: transparent !important
					}

					// Change the border color for all states
					.mdc-radio__outer-circle {
						border-color: colors.$action-clickable !important;
					}
				}
			}

			&:not(:checked) {

				&:hover {
					~.mdc-radio__background {
						border-radius: 50% !important;
						background-color: colors.$action-clickable-light !important;
					}
				}

				&:focus {
					~.mdc-radio__background {
						border-radius: 50% !important;
						background-color: colors.$action-clickable-light !important;

						.mdc-radio__outer-circle {
							border-color: colors.$action-hover !important;
							outline: 1px solid colors.$action-hover !important;
							outline-offset: 2px;
						}

						.mdc-radio__inner-circle {
							border-color: colors.$action-hover !important;
						}
					}
				}

			}

			&:checked {
				~.mdc-radio__background {
					.mdc-radio__inner-circle {
						border-color: white !important;
						border-width: 2px;
						background-color: colors.$action-clickable !important;
					}
				}

				&:hover:not(:focus) {
					~.mdc-radio__background {	
						.mdc-radio__inner-circle {
							background-color: colors.$action-hover !important;
						}
					}
				}

				&:focus {
					~.mdc-radio__background {
						.mdc-radio__outer-circle {
							border-color: colors.$action-hover !important;
							outline: 1px solid colors.$action-hover !important;
							outline-offset: 2px;
						}
	
						.mdc-radio__inner-circle {
							background-color: colors.$action-hover !important;
						}
					}
				}
			}
		}
	}

	// Error state
	// Stylings are virtually the same as above, with colors swapped out for error color
	.cp-error {
		.mdc-radio {
			.mdc-radio__native-control {
				&:enabled {
					~.mdc-radio__background {
						.mdc-radio__outer-circle {
							border-color: colors.$error !important;
						}
					}
				}
	
				&:not(:checked) {
					&:focus {
						~.mdc-radio__background {
							
							.mdc-radio__outer-circle {
								border-color: colors.$error !important;
								outline: 1px solid colors.$error !important;
								outline-offset: 2px;
							}

							.mdc-radio__inner-circle {
								border-color: colors.$error !important;
							}
						}
					}
				}
	
				&:checked {
					~.mdc-radio__background {
						.mdc-radio__inner-circle {
							background-color: colors.$error !important;
						}
					}

					&:hover:not(:focus) {
						~.mdc-radio__background {
							.mdc-radio__inner-circle {
								background-color: colors.$error !important;
							}
						}
					}

					&:focus {
						~.mdc-radio__background {
							.mdc-radio__outer-circle {
								border-color: colors.$error !important;
								outline: 1px solid colors.$error !important;
								outline-offset: 2px;
							}
		
							.mdc-radio__inner-circle {
								background-color: colors.$error !important;
							}
						}
					}
				}
			}
		}
	}
}

@mixin theme($theme) {
	$color-config: mat.get-color-config($theme);
	
	.mdc-radio {
		padding: 0.75rem 0 0 0 !important;
		margin-bottom: 0.75rem !important;

		// Remove pointer events from outside of the control
		.mat-mdc-radio-touch-target {
			top: 0 !important;
			left: 0 !important;
			transform: unset !important;
		}
		
		.mdc-radio__native-control {
			~.mdc-radio__background {
				.mdc-radio__outer-circle {
					border-width: 1px !important;
				}
			}

			&:checked {
				~.mdc-radio__background {
					.mdc-radio__inner-circle {
						transform: scale(0.67) !important;
					}
				}
			}
		}
	}

	@media (min-width: breakpoints.$tablet-min-width) {
		.mdc-radio {
			width: 1.5rem !important;
			height: 1.5rem !important;
			margin-right: 1rem !important;
	
			// Remove pointer events from outside of the control
			.mat-mdc-radio-touch-target {
				height: 1.5rem !important;
				width: 1.5rem !important;
			}
	
			.mdc-radio__native-control {
				// Make the radio a bigger circle
				~.mdc-radio__background {
					width: 1.5rem !important;
					height: 1.5rem !important;
				}
			}
		}
	}

	@media (max-width: breakpoints.$mobile-max-width) {
		.mdc-radio {
			width: 1.25rem !important;
			height: 1.25rem !important;
			margin-right: 0.75rem !important;
	
			// Remove pointer events from outside of the control
			.mat-mdc-radio-touch-target {
				height: 1.25rem !important;
				width: 1.25rem !important;
			}
	
			.mdc-radio__native-control {
				~.mdc-radio__background {
					width: 1.25rem !important;
					height: 1.25rem !important;
				}
			}
		}
	}

	@if $color-config !=null {
		@include color($theme);
	}
}