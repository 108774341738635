@use '/src/scss/breakpoints';
@use '/src/scss/colors';
@use '/src/scss/typography';

.cp-your-home-card {
	color: colors.$gray-90;

	.cp-option-selections-header {
		align-items: center;
		display: flex;
		flex-direction: row;
	}

	mat-tab-header {
		margin-bottom: 2rem;
	}
}

@mixin cp-option-selections-table {
	.cp-option-selections-table {
		margin-bottom: 2.5rem;

		span {
			color: colors.$gray-90 !important;
		}

		tr {
			th {
				vertical-align: bottom !important;
				padding-bottom: 1rem !important;

				h3 {
					color: colors.$brand-primary;
					margin-bottom: 0 !important;
				}
			}

			// Prevent a double-border from the item and detail rows stacking
			~.cp-option-selection-row,
			&.cp-option-selection-row {
				td {
					border-bottom-style: none !important;
				}
			}

			// Collapse the expanded row, if it's not supposed to show
			~.cp-option-selection-detail-row {
				height: 0 !important;

				td {
					padding: 0 !important;
				}
			}

			~.cp-multi-item {
				&.cp-option-selection-detail-row {
					td {
						border-bottom-style: none !important;
					}
				}
			}

			td {
				padding-top: 1rem !important;
				padding-bottom: 1rem !important;
				vertical-align: middle !important;
			}

			th:nth-child(1),
			td:nth-child(1) {
				text-align: left !important;
				padding-left: 0 !important;
			}

			td:nth-child(1) {
				vertical-align: top !important;

				&.cp-single-item {
					vertical-align: middle !important;
				}
			}

			// Quantity
			th:nth-child(3),
			td:nth-child(3) {
				text-align: center !important;
				width: 2rem !important;
			}
		}

		.cp-option-selection-item-label {
			button {
				cursor: pointer;
				margin-left: -0.25rem !important;
				margin-right: 0.75rem !important;

				// Override button defaults
				height: 1.5rem; // Match the height of mat-icon
				padding: 0;
				border: none;
				background: none;

				mat-icon {
					color: colors.$action-clickable;
	
					// Prevent clipping from text in some cases
					min-width: 1.5rem !important;
				}

			}
		}
		
		.cp-option-selection-detail-content {
			@include typography.type-14;
			margin-bottom: unset !important;
			
			overflow: hidden;
			background-color: colors.$gray-10;
			border-radius: 4px;
		}

		.cp-option-selection-detail-content-expanded {
			margin-bottom: 1rem !important;				
		}
	}
	

	// Desktop Styles
	@media screen and (min-width: breakpoints.$desktop-min-width) {
		.cp-option-selections-table {
			tr {
				th {
					@include typography.type-16-bold;
				}
	
				// Decision Point
				td:nth-child(1) {
					width: 10rem !important;
				}
		
				// Choice
				td:nth-child(2) {
					width: 21.5rem !important;
				}
			}
	
			.cp-option-selection-detail-content {
				margin-left: 11rem !important;
				padding-left: 1rem !important;

				.cp-option-selection-description {
					display: block;
					margin: 1rem 1rem 1rem 0;
				}
	
				.cp-group-attribute-location {
					margin: 1rem 0 1rem 0;
				}
			}
		}
	}

	// Tablet Styles
	@media screen and (min-width: breakpoints.$tablet-min-width) and (max-width: breakpoints.$tablet-max-width) {
		.cp-option-selections-table {
			tr {
				th {
					@include typography.type-16-bold;
				}
	
				// Decision Point
				td:nth-child(1) {
					width: 7.75rem !important;
				}
		
				// Choice
				td:nth-child(2) {
					width: 13.125rem !important;
				}
			}
	
			.cp-option-selection-detail-content {
				margin-left: 8.5rem !important;
				padding-left: 1rem !important;
	
				.cp-group-attribute-location {
					margin: 1rem 0 1rem 0;
				}
			}
		}
	}

	// Mobile Styles
	@media screen and (max-width: breakpoints.$mobile-max-width) {
		.cp-option-selections-table {
			tr {
				th {
					@include typography.type-14-bold;
				}

				// Hide the border for the "parent" row that is on its own line, above the item info
				~.cp-item-parent-row,
				&.cp-item-parent-row {
					// This forces the row to take up as little height as possible and honor the padding-bottom: 0
					height: 0 !important;

					td {
						border-bottom-width: 0 !important;
						padding-bottom: 0 !important;
					}
				}

				// Choice (width makes the Qty/Price gap tighter)
				~.cp-option-selection-row td:nth-child(1) {
					width: 50rem !important;
				}

				// Quantity (numbers are different due to Decision Point being its own row)
				th:nth-child(3),
				~.cp-option-selection-row td:nth-child(2) {
					text-align: center !important;
				}
			}

			.cp-option-selection-detail-content {
				padding-left: 1rem !important;
	
				.cp-group-attribute-location {
					margin: 1rem 0 1rem 0;
				}
			}
		}
	}
}