@use "sass:map";
@use "@angular/material" as mat;

@use '/src/scss/breakpoints';

@mixin color($theme) {
	$color-config: mat.get-color-config($theme);
	$primary-palette: map.get($color-config, 'primary');

	.mat-mdc-tooltip {
		background-color: mat.get-color-from-palette($primary-palette, 500);
		color: mat.get-color-from-palette($primary-palette, '500-contrast');

		.mdc-tooltip__surface {
			background-color: inherit !important;
			color: inherit !important;
		}
	}

	.mat-mdc-tooltip::before {
		background-color: mat.get-color-from-palette($primary-palette, 500);
	}
}

@mixin theme($theme) {
	$color-config: mat.get-color-config($theme);

	@if ($color-config != null) {
		@include color($theme);
	}

	.mat-mdc-tooltip-panel-above {
		transform: translateY(0rem) !important; // Overrides inline styles in the cdk-overlay
	}

	.mat-mdc-tooltip-panel-below {
		transform: translateY(-0.75rem) !important; // Overrides inline styles in the cdk-overlay
	}

	.mat-mdc-tooltip {
		top: -0.75rem !important;

		.mdc-tooltip__surface {
			text-align: center !important; // Required to overwrite default of left
			overflow-y: auto;
			scrollbar-width: none;
		}
	}

	// This is the triangle before or after the tooltip
	.mat-mdc-tooltip::before {
		height: 12px;
		width: 12px;
		rotate: 45deg;
		right: 50% !important;
		left: auto !important;
		transform: translate(4px, -4px);
	}
	
	.mat-mdc-tooltip-panel-above .mat-mdc-tooltip::before {
		top: auto;
		bottom: -6px !important;
	}
	
	.mat-mdc-tooltip-panel-below .mat-mdc-tooltip::before {
		top: -6px !important;
		bottom: auto;
	}

	// Desktop specific styles
	@media screen and (min-width: breakpoints.$desktop-min-width) {
		.mat-mdc-tooltip {
			.mdc-tooltip__surface {
				max-width: 20rem;
			}
		}

		//These class live in the SR component and are used to position the tooltips for disabled buttons and links for desktop and tablet.
		.cp-submit-new-request-disabled-tooltip,
		.cp-submit-new-request-link-disabled-tooltip {
			transform: translateY(.5rem) !important
		}
	}

	// Tablet specific styles
	@media screen and (max-width: breakpoints.$mobile-max-width) {
		.mat-mdc-tooltip {
			.mdc-tooltip__surface {
				max-height: 50vh;
				overflow-y: auto;
			}
		}

		//These class live in the SR component and are used to position the tooltips for disabled buttons and links in mobile.
		.cp-submit-new-request-disabled-tooltip-mobile {
			transform: translateY(-0.75rem) !important;
		}

		.cp-submit-new-request-link-disabled-tooltip {
			transform: translateY(.5rem) !important;
		}
	}
}