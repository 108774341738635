@use '/src/scss/colors.scss';
@use '/src/scss/breakpoints.scss';
@use '/src/scss/typography.scss';

$bottom-border-height: 4px;

.cp-header {
	align-items: center;
	background-color: colors.$brand-primary;
	color: colors.$white;
	display: flex;
	height: breakpoints.$header-height;
	justify-content: center;
	padding-left: 4rem;
	padding-right: 5rem;

	&.cp-header-american-west {
		background-color: colors.$brand-tertiary-light;
		border-bottom: $bottom-border-height solid #BE271F; // This color is used only for American West and only here
		color: colors.$brand-primary;
		height: calc(breakpoints.$header-height - $bottom-border-height); // Accounts for bottom border to maintain correct header height
	}

	&.cp-header-john-wieland {
		background-color: colors.$white; // Unlike other brands John Wieland has a white header
		color: colors.$gray-90; // Unlike other brands John Wieland has gray-90 text
	}

	.cp-menu {
		align-items: center;
		display: flex;
		height: breakpoints.$header-height;
		justify-content: center;

		.cp-menu-icon-button {
			height: 4rem;
			width: 4rem;
			padding: 1rem;
	
			.cp-menu-icon {
				height: 2rem;
				width: 2rem;
				font-size: 2rem;
			}

			&:focus {
				border-radius: 0;
			}
		}
	}

	.cp-brand {
		align-items: center;
		display: flex;
		height: inherit;
		flex: 1;

		.cp-brand-img {
			max-height: 57px; // Needed for SVGs that are larger than the header
			max-width: 134px;
		}
	}

	.cp-header-info {
		display: flex;
		gap: 0.5rem;
		cursor: pointer;

		a {
			@include typography.type-16;
			margin: 0rem;
		}

		.cp-carrot-icon {
			width: 1.5rem;
			height: 1.5rem;
			flex-shrink: 0;
		}
	}
}

// Custom styles for the our custom CDK Overlay for the my account button
#cp-custom-container-aria {
	.cdk-overlay-backdrop {
		width: 100vw !important;
		height: 100vh !important;
		opacity: 0 !important;
	}
}

.cp-my-account-menu-overlay {
	right: 5rem !important;

	.cp-my-account-popup {
		padding: .35rem;
		display: flex;
		flex-direction: column;
		background-color: colors.$white;
		box-shadow: 
			0rem 0.313rem 0.313rem -0.188rem rgba(0, 0, 0, 0.2), 
			0rem 0.5rem 0.625rem 0.063rem rgba(0, 0, 0, 0.14), 
			0rem 0.188rem 0.875rem 0.125rem rgba(0, 0, 0, 0.12);
		border-radius: 0.25rem !important;
		margin-top: 1rem;
		height: 4rem !important;

		a, button {
			flex: 1;
			justify-content: left;

			&:focus-visible {
				outline: 3px solid colors.$action-focus; // Adds a visible outline when focused
			}
	
			&:focus {
				border-radius: 0.25rem !important;
			}
		}
	}
}


// Desktop Styles
@media screen and (min-width: breakpoints.$desktop-min-width) {
	.cp-header {
		justify-content: space-between;

		.cp-brand {
			margin: 0rem;
		}
	}
}

// Tablet Styles
@media screen and (min-width: breakpoints.$tablet-min-width) and (max-width: breakpoints.$tablet-max-width) {
	.cp-header {
		padding: 0rem;

		&.cp-header-american-west {
			.cp-menu {
				border-bottom: $bottom-border-height solid colors.$brand-tertiary-light;
			}
		}

		.cp-menu {
			background-color: colors.$brand-tertiary-light;
			width: breakpoints.$tablet-navigation-width;

			.cp-menu-icon-button {
				.cp-menu-icon {
					color: colors.$brand-primary;
				}

				&:focus {
					outline: 1px solid colors.$brand-primary;
				}
			}
		}
		
		.cp-brand {
			flex: 1;
			justify-content: center;
		}
	}
}

// Mobile Styles
@media screen and (max-width: breakpoints.$mobile-max-width) {
	.cp-header {
		height: breakpoints.$mobile-header-height;
		padding: 0rem;

		&.cp-header-american-west {
			height: calc(breakpoints.$mobile-header-height - $bottom-border-height);
		}

		.cp-brand {
			.cp-brand-img {
				max-height: 28px; // Needed for SVGs that are larger than the mobile header
			}
		}

		.cp-menu {
			position: absolute;
			left: 0rem;
			padding-left: 0.5rem;

			.cp-menu-icon-button {
				height: 3rem;
				width: 3rem;
				padding: 0.75rem;
		
				.cp-menu-icon {
					height: 1.5rem;
					width: 1.5rem;
					font-size: 1.5rem;
				}

				&:focus {
					outline: 1px solid currentColor;
				}
			}
		}

		.cp-brand {
			flex: 1;
			justify-content: center;
		}
	}
}

@mixin cp-header-mat-menu {
	.cp-header-mat-menu {
		background-color: colors.$white;
		box-shadow: colors.$drop-shadow-light;
		margin-top: 1rem;
	}
}
