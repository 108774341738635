@use "sass:map";
@use "@angular/material" as mat;

@use '/src/scss/breakpoints';
@use '/src/scss/colors';
@use '/src/scss/typography';

@mixin theme($theme) {
	$color-config: mat.get-color-config($theme);

	// Hides the default arrow so it can be replaced with mat-icon
	.mat-select-arrow,
	.mat-mdc-select-arrow {
		display: none;
	}

	// Makes selected option text the parent color instead of primary
	.mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) {
		.mdc-list-item__primary-text {
			color: inherit !important;
		}
	}

	// Focus behavior for accessibility (contrast ratio)
	.mat-mdc-option:focus.mdc-list-item, .mat-mdc-option.mat-mdc-option-active.mdc-list-item,
	.mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled):not(.mat-mdc-option-multiple) {
		color: colors.$white;
		background-color: colors.$brand-primary !important;
	}

	// Makes the option checkbox the parent color instead of primary
	.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after {
		color: inherit !important;
	}

	// Sets the text displayed within the select to type 16 light
	.mat-mdc-select-value-text,
	.mat-mdc-select-placeholder {
		@include typography.type-16-light;
	}

	@media (max-width: breakpoints.$mobile-max-width) {
		// Overrides default line-height
		.mat-mdc-select {
			line-height: 1.25rem !important;

			// Sets the text displayed within the select to type 16 light
			.mat-mdc-select-value-text,
			.mat-mdc-select-placeholder {
				@include typography.type-14-light;
			}

			.mat-mdc-select-trigger {
				max-height: 1.25rem;
			}
		}

		.mat-mdc-option {
			min-height: unset !important;
			height: 2.5rem !important;
			max-height: 2.5rem !important;
		}
	}
}
