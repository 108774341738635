// Pulte Brand Fonts
@font-face {
	font-family: 'Lineto-Brown-Pro';
	src: url('./lineto-brown-pro/lineto-brown-pro-bold.woff') format('woff');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Lineto-Brown-Pro';
	src: url('./lineto-brown-pro/lineto-brown-pro-light.woff') format('woff');
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: 'Lineto-Brown-Pro';
	src: url('./lineto-brown-pro/lineto-brown-pro-regular.woff') format('woff');
	font-weight: 400;
	font-style: normal;
}

// Centex Brand Fonts
@font-face {
	font-family: 'Apercu';
	src: url('./apercu/apercu-bold.woff2') format('woff2'),
		url('./apercu/apercu-bold.woff') format('woff');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Apercu';
	src: url('./apercu/apercu-light.woff2') format('woff2'),
		url('./apercu/apercu-light.woff') format('woff');
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: 'Apercu';
	src: url('./apercu/apercu-regular.woff2') format('woff2'),
		url('./apercu/apercu-regular.woff') format('woff');
	font-weight: 400;
	font-style: normal;
}

// Delwebb Brand Fonts
@font-face {
	font-family: 'Museo-Sans';
	src: url('./museo-sans/museo-sans-bold.woff2') format('woff2'),
		url('./museo-sans/museo-sans-bold.woff') format('woff');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Museo-Sans';
	src: url('./museo-sans/museo-sans-light.woff2') format('woff2'),
		url('./museo-sans/museo-sans-light.woff') format('woff');
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: 'Museo-Sans';
	src: url('./museo-sans/museo-sans-regular.woff2') format('woff2'),
		url('./museo-sans/museo-sans-regular.woff') format('woff');
	font-weight: 400;
	font-style: normal;
}

// DiVosta Fonts
@font-face {
	font-family: 'Stag-Sans';
	src: url('./stag-sans/stag-sans-semibold.woff') format('woff');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Stag-Sans';
	src: url('./stag-sans/stag-sans-book.woff') format('woff');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Stag-Sans';
	src: url('./stag-sans/stag-sans-light.woff') format('woff');
	font-weight: 400;
	font-style: normal;
}

// American West Brand Fonts
@font-face {
	font-family: 'Optima-Linotype';
	src: url('./optima-linotype/optima-linotype-bold.woff2') format('woff2'),
		url('./optima-linotype/optima-linotype-bold.woff') format('woff');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Optima-Linotype';
	src: url('./optima-linotype/optima-linotype-roman.woff2') format('woff2'),
		url('./optima-linotype/optima-linotype-roman.woff') format('woff');
	font-weight: 400;
	font-style: normal;
}

// John Wieland Fonts
@font-face {
	font-family: 'Palatino-Linotype';
	src: url('./palatino-linotype/palatino-linotype-bold.woff2') format('woff2'),
		url('./palatino-linotype/palatino-linotype-bold.woff') format('woff');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Palatino-Linotype';
	src: url('./palatino-linotype/palatino-linotype-regular.woff2') format('woff2'),
		url('./palatino-linotype/palatino-linotype-regular.woff') format('woff');
	font-weight: 400;
	font-style: normal;
}