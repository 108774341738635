@use "sass:map";
@use "@angular/material" as mat;

@use '/src/scss/colors.scss';
@use '/src/scss/typography.scss';
@use "/src/scss/_breakpoints.scss";

/* The use of !important throughout this file is to override inline
styles as part of the Angular Material default button theme */

@mixin color($theme) {
	$color-config: mat.get-color-config($theme);
	$accent-palette: map.get($color-config, 'accent');

	//Colors for enabled buttons
	.mat-mdc-button:not(:disabled) {
		&.mat-primary {
			background-color: mat.get-color-from-palette($accent-palette, 400);
			border: 1px solid mat.get-color-from-palette($accent-palette, 400);
			color: mat.get-color-from-palette($accent-palette, '400-contrast');
		}

		&.mat-accent {
			border: 1px solid mat.get-color-from-palette($accent-palette, 400);
			color: mat.get-color-from-palette($accent-palette, 400);
		}

		// No border or background color for unthemed buttons. Acts like a link
		&.mat-unthemed {
			color: mat.get-color-from-palette($accent-palette, 400);
		}
	}
	
	.mat-mdc-fab:not(:disabled),
	.mat-mdc-mini-fab:not(:disabled) {
		background-color: mat.get-color-from-palette($accent-palette, 400);
		color: mat.get-color-from-palette($accent-palette, '400-contrast');
	}

	// Colors for disabled buttons
	.mat-mdc-button:disabled {
		&.mat-primary {
			background-color: mat.get-color-from-palette(colors.$cp-background, 200);
			border: 1px solid mat.get-color-from-palette(colors.$cp-background, 200);
			color: mat.get-color-from-palette(colors.$cp-background, 600);
		}

		&.mat-accent {
			background-color: mat.get-color-from-palette(colors.$cp-background, 100);
			border: 1px solid mat.get-color-from-palette(colors.$cp-background, 300);
			color: mat.get-color-from-palette(colors.$cp-background, 700);
		}

		&.mat-unthemed {
			color: mat.get-color-from-palette(colors.$cp-background, 600);
		}
	}

	// Colors for focus and hover of buttons
	.mat-mdc-button:hover,
	.mat-mdc-button:focus,
	.mat-mdc-button:focus-within,
	.mat-mdc-button:focus-visible {
		transition: 0.3s; // Fade colors in on hover

		&.mat-primary {
			background-color: mat.get-color-from-palette($accent-palette, 500);
			border: 1px solid mat.get-color-from-palette($accent-palette, 500);
			color: mat.get-color-from-palette($accent-palette, '500-contrast');
		}

		&.mat-accent {
			background-color: mat.get-color-from-palette($accent-palette, 100);
			border: 1px solid mat.get-color-from-palette($accent-palette, 500);
			color: mat.get-color-from-palette($accent-palette, 500);
		}

		&.mat-unthemed {
			--mat-mdc-button-persistent-ripple-color: none; // Modify CSS3 variable to remove the ripple color on an unthemed button(link)
			color: mat.get-color-from-palette(colors.$cp-accent, 500);
		}
	}
	
	.mat-mdc-fab:hover,
	.mat-mdc-fab:focus,
	.mat-mdc-fab:focus-within,
	.mat-mdc-fab:focus-visible,
	.mat-mdc-mini-fab:hover,
	.mat-mdc-mini-fab:focus,
	.mat-mdc-mini-fab:focus-within,
	.mat-mdc-mini-fab:focus-visible {
		transition: 0.3s; // Fade colors in on hover

		background-color: mat.get-color-from-palette($accent-palette, 500);
		color: mat.get-color-from-palette($accent-palette, '500-contrast');
	}
}

@mixin theme($theme) {
	$color-config: mat.get-color-config($theme);

	.mdc-button {
		@include typography.type-16-light;
		border-radius: 1.5rem !important;
		gap: 0.75rem;
		height: 3rem !important;
		margin: 0rem;
		padding: 0.75rem 2rem !important;
		white-space: nowrap;

		>.mat-icon {
			font-size: 1.5rem !important;
			height: 1.5rem !important;
			min-width: 1.5rem;
			margin: 0rem !important;
			font-weight: 200;
		}
	}

	// Not currently supported in firefox, but will be soon
	.mdc-button:has(mat-icon + .mdc-button__label) {
		padding: 0.75rem 2rem 0.75rem 1.5rem !important;
	}

	.mdc-button:has(.mdc-button__label + mat-icon) {
		padding: 0.75rem 1.5rem 0.75rem 2rem !important;
	}

	.mdc-button:focus,
	.mdc-button:focus-within
	.mdc-button:focus-visible {
		border-radius: 1.625rem !important;
		outline-offset: 2px !important;
	}

	.mdc-fab {
		font-weight: 200 !important;
		box-shadow: 0px 4px 15px 5px rgba(164, 171, 180, 0.30) !important;

		>.mat-icon {
			font-size: 2rem;
			font-weight: 200;
			height: 2rem;
			min-width: 2rem;
		}
	}

	.mdc-fab--mini {
		box-shadow: 0px 4px 15px 5px rgba(164, 171, 180, 0.30) !important;

		>.mat-icon {
			font-size: 1rem;
			font-weight: 200;
			height: 1rem;
			min-width: 1rem;
		}
	}

	@if $color-config != null {
		@include color($theme);
	}

	//Media query for mobile view
	@media screen and (max-width: breakpoints.$mobile-max-width) {
		.mdc-button {
			@include typography.type-14-light;
			gap: 0.5rem;
			margin: 0rem;
			max-height: 2.25rem;
			padding: 0.5rem 1.5rem !important;
		}

		.mdc-button:has(mat-icon + .mdc-button__label) {
			padding: 0.375rem 1.5rem 0.375rem 1rem !important;
		}

		.mdc-button:has(.mdc-button__label + mat-icon) {
			padding: 0.375rem 1rem 0.375rem 1.5rem !important;
		}
	}
}