@use '/src/scss/fonts/fonts.scss';

@mixin typography-level($font-size, $font-weight, $line-height, $paragraph-space) {
	font-family: var(--font-regular);
	font-size: $font-size;
	font-weight: $font-weight;
	line-height: $line-height;
	margin-bottom: $paragraph-space;
	letter-spacing: 0;
}

@mixin type-48 {
	@include typography-level(3rem, normal, 3.75rem, 2rem);
}

@mixin type-48-light {
	@include typography-level(3rem, lighter, 3.75rem, 2rem);
}

@mixin type-48-bold {
	@include typography-level(3rem, bold, 3.75rem, 2rem);
}

@mixin type-40 {
	@include typography-level(2.5rem, normal, 3.5rem, 1.5rem);
}

@mixin type-40-light {
	@include typography-level(2.5rem, lighter, 3.5rem, 1.5rem);
}

@mixin type-40-bold {
	@include typography-level(2.5rem, bold, 3.5rem, 1.5rem);
}

@mixin type-32 {
	@include typography-level(2rem, normal, 3rem, 1.5rem);
}

@mixin type-32-light {
	@include typography-level(2rem, lighter, 3rem, 1.5rem);
}

@mixin type-32-bold {
	@include typography-level(2rem, bold, 3rem, 1.5rem);
}

@mixin type-24 {
	@include typography-level(1.5rem, normal, 2rem, 1rem);
}

@mixin type-24-light {
	@include typography-level(1.5rem, lighter, 2rem, 1rem);
}

@mixin type-24-bold {
	@include typography-level(1.5rem, bold, 2rem, 1rem);
}

@mixin type-20 {
	@include typography-level(1.25rem, normal, 2rem, 1rem);
}

@mixin type-20-light {
	@include typography-level(1.25rem, lighter, 2rem, 1rem);
}

@mixin type-20-bold {
	@include typography-level(1.25rem, bold, 2rem, 1rem);
}

@mixin type-16 {
	@include typography-level(1rem, normal, 1.5rem, 0.75rem);
}

@mixin type-16-light {
	@include typography-level(1rem, lighter, 1.5rem, 0.75rem);
}

@mixin type-16-bold {
	@include typography-level(1rem, bold, 1.5rem, 0.75rem);
}

@mixin type-14 {
	@include typography-level(0.875rem, normal, 1.25rem, 0.75rem);
}

@mixin type-14-light {
	@include typography-level(0.875rem, lighter, 1.25rem, 0.75rem);
}

@mixin type-14-bold {
	@include typography-level(0.875rem, bold, 1.25rem, 0.75rem);
}

@mixin type-12 {
	@include typography-level(0.75rem, normal, 1.125rem, 0.75rem);
}

@mixin type-12-light {
	@include typography-level(0.75rem, lighter, 1.125rem, 0.75rem);
}

@mixin type-12-bold {
	@include typography-level(0.75rem, bold, 1.125rem, 0.75rem);
}

@mixin type-10 {
	@include typography-level(0.625rem, normal, 0.875rem, 0.5rem);
}

@mixin type-10-light {
	@include typography-level(0.625rem, lighter, 0.875rem, 0.5rem);
}

@mixin type-10-bold {
	@include typography-level(0.625rem, bold, 0.875rem, 0.5rem);
}

// Classes to be applied generally as needed
.cp-mat-icon-14 {
	transform: scale(.70);
}

.cp-type-48 {
	@include typography-level(3rem, normal, 3.75rem, 2rem);
}

.cp-type-48-light {
	@include typography-level(3rem, lighter, 3.75rem, 2rem);
}

.cp-type-48-bold {
	@include typography-level(3rem, bold, 3.75rem, 2rem);
}

.cp-type-40 {
	@include typography-level(2.5rem, normal, 3.5rem, 1.5rem);
}

.cp-type-40-light {
	@include typography-level(2.5rem, lighter, 3.5rem, 1.5rem);
}

.cp-type-40-bold {
	@include typography-level(2.5rem, bold, 3.5rem, 1.5rem);
}

.cp-type-32 {
	@include typography-level(2rem, normal, 3rem, 1.5rem);
}

.cp-type-32-light {
	@include typography-level(2rem, lighter, 3rem, 1.5rem);
}

.cp-type-32-bold {
	@include typography-level(2rem, bold, 3rem, 1.5rem);
}

.cp-type-24 {
	@include typography-level(1.5rem, normal, 2rem, 1rem);
}

.cp-type-24-light {
	@include typography-level(1.5rem, lighter, 2rem, 1rem);
}

.cp-type-24-bold {
	@include typography-level(1.5rem, bold, 2rem, 1rem);
}

.cp-type-20 {
	@include typography-level(1.25rem, normal, 2rem, 1rem);
}

.cp-type-20-light {
	@include typography-level(1.25rem, lighter, 2rem, 1rem);
}

.cp-type-20-bold {
	@include typography-level(1.25rem, bold, 2rem, 1rem);
}

.cp-type-16 {
	@include typography-level(1rem, normal, 1.5rem, 0.75rem);
}

.cp-type-16-light {
	@include typography-level(1rem, lighter, 1.5rem, 0.75rem);
}

.cp-type-16-bold {
	@include typography-level(1rem, bold, 1.5rem, 0.75rem);
}

.cp-type-14 {
	@include typography-level(0.875rem, normal, 1.25rem, 0.75rem);
}

.cp-type-14-light {
	@include typography-level(0.875rem, lighter, 1.25rem, 0.75rem);
}

.cp-type-14-bold {
	@include typography-level(0.875rem, bold, 1.25rem, 0.75rem);
}

.cp-type-12 {
	@include typography-level(0.75rem, normal, 1.125rem, 0.75rem);
}

.cp-type-12-light {
	@include typography-level(0.75rem, lighter, 1.125rem, 0.75rem);
}

.cp-type-12-bold {
	@include typography-level(0.75rem, bold, 1.125rem, 0.75rem);
}

.cp-type-10 {
	@include typography-level(0.625rem, normal, 0.875rem, 0.5rem);
}

.cp-type-10-light {
	@include typography-level(0.625rem, lighter, 0.875rem, 0.5rem);
}

.cp-type-10-bold {
	@include typography-level(0.625rem, bold, 0.875rem, 0.5rem);
}