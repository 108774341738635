@use '/src/scss/breakpoints.scss';
@use '/src/scss/colors.scss';
@use '/src/scss/typography.scss';

.cp-navigation {
	background-color: colors.$brand-tertiary-light;
	border-right: 1px solid colors.$gray-30;
	color: colors.$gray-90;
	display: flex;
	flex-direction: column;
	height: 100%;
	overflow: auto;
	width: breakpoints.$desktop-navigation-width;

	.cp-greeting {
		display: flex;
		flex-direction: column;
		gap: 0.75rem;
		padding: 4rem 4rem 0.5rem 4rem;

		h2 {
			@include typography.type-32;
			margin: 0rem;
		}

		span {
			@include typography.type-16;
			margin: 0rem;
		}
	}

	.cp-navigation-list {
		display: flex; // Couldn't use cp-flex-container because it gets overwritten by mat-list classes
		flex-direction: column;
		gap: 0.75rem;
		padding: 2.5rem;

		.cp-navigation-item {
			height: 4rem;
			padding: 0rem 1.25rem;
			text-align: center;

			.cp-navigation-item-content {
				align-items: center;
				display: flex;
				flex-shrink: 0;
				gap: 1rem;
				padding: 0.75rem 0rem;

				button {
					// Remove default HTML button stylings
					&.cp-navigation-link {
						background: none;
						border: none;
						color: colors.$gray-90;
					}
				}

				.cp-navigation-link {
					@include typography.type-16-light;
					display: flex;
					align-items: center;
					gap: 0.75rem;
					margin-bottom: 0rem;
					text-decoration: none;
					white-space: initial;
					cursor: pointer;

					.cp-navigation-icon {
						fill: none;
						stroke: currentColor;
						stroke-width: 0.5rem;
						font-weight: lighter;
						font-size: 2rem;
						height: 2rem;
						width: 2rem;
					}
				}
			}
		}

		.cp-navigation-item-selected {
			background-color: colors.$white;
			border-radius: 0.25rem;

			.cp-navigation-item-content {

				.cp-navigation-link {
					@include typography.type-16-bold;
					margin-bottom: 0rem;

					.cp-navigation-icon {
						fill: currentColor;
						color: colors.$brand-secondary;
						font-weight: 400;
						width: 2.125rem !important;
						height: 2.125rem !important;
					}
				}
			}
		}

		// Prevents icon and anchor tag color from changing when interacting with it
		a, a:visited, a:hover, a:active {
			color: inherit;
		}
	}
}

// Desktop Styles
@media screen and (min-width: breakpoints.$desktop-min-width) {
	.cp-navigation-list {
		overflow: auto;
	}
}

// Tablet Styles
@media screen and (min-width: breakpoints.$tablet-min-width) and (max-width: breakpoints.$tablet-max-width) {
	.cp-navigation {
		width: breakpoints.$tablet-navigation-width;

		.cp-navigation-list {
			gap: 0.5rem;
			padding: 0.5rem 0.25rem;

			.cp-navigation-item {
				height: fit-content;
				padding: 0rem;

				.cp-navigation-item-content {
					flex-direction: column;
					gap: 0.25rem;

					.cp-navigation-link {
						@include typography.type-10-light;
						align-items: center;
						display: flex; // Couldn't use cp-flex-container because it gets overwritten by mat-list classes
						flex-direction: column;
						margin-bottom: 0rem;
					}
				}
			}

			.cp-navigation-item-selected {
				.cp-navigation-item-content {
					.cp-navigation-link {
						@include typography.type-10-bold;
						margin-bottom: 0rem;
					}
				}
			}
		}
	}
}

// Mobile Styles
@media screen and (max-width: breakpoints.$mobile-max-width) {
	.cp-navigation {
		border-right: none;
		border-top: 1px solid colors.$gray-30;
		height: breakpoints.$mobile-navigation-height;
		width: 100vw;

		// Sticky styles
		position: -webkit-sticky;
		position: sticky;
		bottom: 0;

		.cp-navigation-list {
			display: flex;
			flex-direction: row;
			gap: 0rem;
			height: 100%;
			padding: 0.19rem 0.25rem;

			.cp-navigation-item {
				height: 100%;
				max-height: breakpoints.$mobile-navigation-height;
				padding: 0rem;

				.cp-navigation-item-content {
					gap: 0rem;
					justify-content: space-evenly;
					padding: 0rem;
					flex-direction: column;

					.cp-navigation-icon {
						font-size: 2rem;
						height: 2rem;
						width: 2rem;
					}

					.cp-navigation-link {
						@include typography.type-10-light;
						flex-direction: column;
						gap: 0.25rem;
						margin-bottom: 0rem;
						justify-content: space-evenly;
					}
				}
			}

			.cp-navigation-item-selected {
				.cp-navigation-item-content {
					.cp-navigation-link {
						@include typography.type-10-bold;
						margin-bottom: 0rem;
					}
				}
			}
		}
	}
}

@mixin cp-overflow-menu {
	.cp-overflow-menu {
		background-color: colors.$brand-tertiary-light !important;
		
		&.cdk-overlay-backdrop {
			background-color: colors.$black !important;
			opacity: 0.8;
		}

		.cp-navigation-item-selected {
			background-color: colors.$white;

			.cp-navigation-item-content {
				.cp-navigation-icon {
					color: colors.$brand-secondary;
				}
			}
		}
	}

	@media screen and (max-width: breakpoints.$mobile-max-width) {
		.cdk-overlay-pane:has(.cp-overflow-menu) {
			bottom: 0 !important; // Overwrite an inline bottom attribute applied to more menu because of some padding on the more tile
		}

		.cp-overflow-menu {
			box-shadow: none !important;
			max-width: 100% !important;
			padding: 1rem 1rem 0rem 1rem;
			width: 100vw;

			&.mat-mdc-menu-panel {
				border-radius: 0px !important;
				position: relative;
			}

			.cp-navigation-item {
				height: 4rem !important;

				.cp-navigation-item-content {
					align-items: center;
					display: flex;
					gap: 1rem;

					.cp-navigation-link {
						@include typography.type-14-light;
						margin-bottom: 0rem;
						text-decoration: none;
						color: inherit;
						display: flex;
						align-items: center;
						gap: 1rem;

						.cp-navigation-icon {
							fill: none;
							stroke: currentColor;
							stroke-width: 0.5rem;
							font-weight: lighter;
							font-size: 2rem;
							height: 2rem;
							width: 2rem;
						}
					}
				}
			}

			.cp-navigation-item-selected {
				border-radius: 0.25rem;

				.cp-navigation-item-content {

					.cp-navigation-link {
						@include typography.type-14-bold;
						margin-bottom: 0rem;

						.cp-navigation-icon {
							fill: currentColor;
							width: 2.125rem !important;
							height: 2.125rem !important;
						}
					}
				}
			}

			.cp-close-item {
				display: flex;
				align-items: flex-end;
				height: breakpoints.$mobile-navigation-height !important;

				.cp-close-content {
					align-items: center;
					display: flex;
					flex-direction: column;
					gap: 0.62rem;
					margin-left: auto;
					margin-right: 0rem;
					width: fit-content;
					
					.cp-close-button {
						@include typography.type-10-light;
						background: none;
						border: none;
						color: colors.$gray-90;
						margin-bottom: 0rem;
					}
				}
			}
		}
	}
}