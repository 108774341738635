@use '@angular/material' as mat;

// Neutrals - Brand Agnostic
$white: #FFFFFF;
$gray-05: #FCFCFC;
$gray-10: #F5F5F5;
$gray-20: #EEEEEE;
$gray-30: #E3E3E3;
$gray-40: #C2C2C2;
$gray-50: #ABABAB;
$gray-60: #B2B2B2;
$gray-70: #6C6C6C;
$gray-80: #494949;
$gray-90: #2D2D2D;
$black: #000000;

// Semantic - Brand Agnostic
$success: #3E7633;
$warning: #DD742D;  // UI Note: Orange was chose as to not conflict with the yellow gold in brand accents
$error: #C23F38;
$discount: #9A2C22;

// Brand Colors
$brand-primary: var(--brand-primary);
$brand-secondary: var(--brand-secondary);
$brand-tertiary: var(--brand-tertiary);
$brand-tertiary-light: var(--brand-tertiary-light);
$brand-accent-gold: var(--brand-accent-gold);

// Action Colors - Brand Specific
$action-clickable: var(--action-clickable);
$action-hover: var(--action-hover);
$action-clickable-light: var(--action-clickable-light);
$action-focus: var(--action-focus);

// Box Shadow Colors
$drop-shadow-light: 0px 4px 15px 2px rgba(164, 171, 180, 0.15);
$drop-shadow-dark: 0px 4px 15px 5px rgba(164, 171, 180, 0.30);

// Angular Material Themes - Color Palettes
$cp-primary: (
	100: $brand-tertiary-light,
	500: $brand-primary,
	700: $brand-secondary,
	900: $brand-tertiary,
	contrast: (
		500: $white,
		700: $white,
		A400: $white
	)
);

$cp-accent: (
	100: $action-clickable-light,
	400: $action-clickable,
	500: $action-hover,
	700: $action-focus,
	contrast: (
		400: $white,
		500: $white,
		A400: $white
	)
);

$cp-background: (
	50: $white,
	100: $gray-05,
	200: $gray-20,
	300: $gray-40,
	400: $gray-50,
	500: $gray-60,
	600: $gray-70,
	700: $gray-80,
	800: $gray-90,
	900: $black,
	A200: $gray-10,
	A400: $gray-30,
	contrast: (
		500 : $white,
		A400 : $white
	)
);

$cp-warn: (
	100: $warning, //Warning: proceed with caution
	500: $success, //Positive: successful action
	700: $error, //Negative: dangerous action, error
	contrast: (
		500 : $white,
		A400 : $white
	)
);

$color-primary: mat.define-palette($cp-primary, 500, 700, 900, 100);
$color-accent: mat.define-palette($cp-accent, 400, 700, 500, 100);
$color-warn: mat.define-palette($cp-warn, 700, 100, 500);

$color-theme: (
	primary: $color-primary,
	accent: $color-accent,
	warn: $color-warn
);
