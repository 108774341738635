// Media Query Breakpoints
$mobile-max-width: 479.98px;
$tablet-min-width: 480px;
$tablet-max-width: 1279.98px;
$desktop-min-width: 1280px;

// UI Element Heights
$bottom-border-height-american-west: 4px;
$header-height: 5rem;
$mobile-header-height: 3.125rem;
$mobile-navigation-height: 5.5rem;
$tablet-navigation-width: 6.25rem;
$desktop-navigation-width: 22.5rem;