@use "sass:map";

@use '/src/scss/breakpoints';
@use '/src/scss/colors';

/* The use of !important throughout this file is to override inline
styles as part of the Angular Material default card theme */

@mixin theme($theme) {
	.mdc-card {
		box-shadow: colors.$drop-shadow-light !important;
		flex-shrink: 0 !important;
		border-radius: 0.25rem !important;
		border: 1px solid colors.$gray-30 !important;
		box-sizing: border-box !important;

		.mat-mdc-card-header {
			padding: unset !important;
		}

		.mat-mdc-card-content {
			padding: unset !important;
		}

		.mdc-card__actions {
			padding: unset !important;
		}
	}

	// Applies the brand's primary color as a top border on a card
	.cp-bordered-card {
		border-top: 0.25rem solid colors.$brand-primary !important;
	}

	cp-post-close-experience {
		.mat-mdc-card-content {
			p {
				display: flex;
				flex-direction: column;
				gap: 1rem;
			}	
		}
	}

	@media screen and (max-width: breakpoints.$mobile-max-width) {
		.mdc-card {
			box-shadow: unset !important;
			border-radius: unset !important;
			border: unset !important;
		}
	}
}