@use "sass:map";
@use "@angular/material" as mat;

/* The use of !important throughout this file is to override inline
styles as part of the Angular Material default table theme */

@mixin color($theme) {
}

@mixin theme($theme) {
	$color-config: mat.get-color-config($theme);

	@if $color-config !=null {
		@include color($theme);
	}
}